import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';
import { UserService } from '@/service/User';
import axios from 'axios';
import { API_END_POINT, LOG_API_END_POINT } from '../config';
import { OW_API_LATENCY_FE } from '@/constants/Events';

export const apiAxiosV2 = axios.create({
  baseURL: API_END_POINT + '/v2',
  timeout: 120000
});
export const apiAxiosV1 = axios.create({
  baseURL: API_END_POINT + '/v1',
  timeout: 120000
});
export const apiLogAxiosV1 = axios.create({
  baseURL: LOG_API_END_POINT + '/v1',
  timeout: 120000
});

export const authAxiosV1 = axios.create({
  baseURL: API_END_POINT + '/v1',
  timeout: 120000
});

const latencyReporter = captureAPILatency(report => {
  const { method, time, type, url, statusCode } = report;
  AndroidService.logEvent(
    OW_API_LATENCY_FE,
    JSON.stringify({
      url,
      method,
      latency: time,
      type,
      statusCode
    })
  );
});

apiAxiosV2.interceptors.request.use(
  compose(latencyReporter.interceptRequest, addAccessTokenInterceptor)
);
apiAxiosV2.interceptors.response.use(
  compose(latencyReporter.interceptSuccessResponse, checkIfNetworkStillAlive),
  compose(latencyReporter.interceptErrorResponse, processAuthNetworkErrors)
);
apiAxiosV1.interceptors.request.use(
  compose(latencyReporter.interceptRequest, addAccessTokenInterceptor)
);
apiAxiosV1.interceptors.response.use(
  compose(latencyReporter.interceptSuccessResponse, checkIfNetworkStillAlive),
  compose(latencyReporter.interceptErrorResponse, processAuthNetworkErrors)
);

function checkIfNetworkStillAlive(data) {
  if (!window.navigator.onLine) {
    dispatchEvent(new Event('online'));
  }

  return data;
}

function addAccessTokenInterceptor(config) {
  const headers = config.headers || {};

  const accessToken = StorageService.getAccessToken();
  const profileId = StorageService.get('profile_id', true);
  const deviceModel = AndroidService.getUserDeviceName();
  const isDebugEnabled = AndroidService.isDebugEnabled();

  if (accessToken) headers['auth-token'] = accessToken;
  if (profileId) headers['x-profile'] = profileId;
  headers['x-language'] = 'ENGLISH';
  headers['x-device-model'] = deviceModel;
  headers['x-debug'] = isDebugEnabled;
  config.headers = headers;

  return config;
}

function isTokenExpired(error) {
  if (!error.config) return false;
  const isTokenExpired =
    error.response?.status === 401 ||
    error.response?.data.error === 'Authentication failed';
  return isTokenExpired;
}

async function processAuthNetworkErrors(err) {
  const shouldRefreshAccessToken = isTokenExpired(err);

  if (shouldRefreshAccessToken) {
    try {
      const token = await getNewAuthAccessToken();
      err.config.headers['auth-token'] = token;
      return new Promise(resolve => {
        setTimeout(() => {
          err.config.__retryCount = (err.config.__retryCount || 0) + 1;
          return resolve(axios(err.config));
        }, 1000);
      });
    } catch {
      throw new Error('failed to refresh tokens');
    }
  } else {
    return Promise.reject(err);
  }
}

const getNewAuthAccessToken = withProtectedTask(async () => {
  try {
    const res = await authAxiosV1.post('/offerwall/user/refresh', {
      refresh_token: StorageService.getAuthRefreshToken()
    });
    StorageService.setAccessToken(res.data.auth_token);
    StorageService.setAuthRefreshToken(
      res.data.refresh_token || StorageService.getAuthRefreshToken()
    );
    return res.data.auth_token;
  } catch {
    StorageService.setAccessToken(null);
    StorageService.setAuthRefreshToken(null);
    return;
  }
});

function withProtectedTask(fn) {
  const cachedPromise = {};

  return function wrapper() {
    const hasArgs = !!arguments.length;
    const hash = arguments.length ? getObjectHash(arguments) : '__DEFAULT__';

    if (hasArgs) {
      console.warn(
        '[unsafe_withProtectedTask] Be careful if you are willing to use in production...'
      );
    }

    if (cachedPromise[hash]) {
      return cachedPromise[hash];
    }

    cachedPromise[hash] = fn
      .apply(null, arguments)
      .then(data => {
        cachedPromise[hash] = null;
        return data;
      })
      .catch(err => {
        cachedPromise[hash] = null;
        return Promise.reject(err);
      });

    return cachedPromise[hash];
  };
}

function getObjectHash(obj) {
  //bechmark: https://codesandbox.io/s/getobjecthash-m78qb?file=/src/index.js
  return JSON.stringify(obj);
}

function captureAPILatency(reporter) {
  function interceptRequest(config) {
    if (!config.__extra) {
      config.__extra = {};
    }

    config.__extra.requestStart = Date.now();

    return config;
  }

  function interceptSuccessResponse(res) {
    if (!res.config.__extra) {
      res.config.__extra = {};
    }
    res.config.__extra.requestEnd = Date.now();

    doReport({
      reportType: 'ok',
      config: res.config,
      statusCode: res.status || -1
    });

    return res;
  }

  function interceptErrorResponse(err) {
    if (axios.isCancel(err)) {
      return;
    }

    if (!err.config) {
      err.config = {};
    }

    if (!err.config.__extra) {
      err.config.__extra = {};
    }

    err.config.__extra.requestEnd = Date.now();

    doReport({
      reportType: 'error',
      config: err.config,
      statusCode: err.response?.status || err.status || -1
    });

    return err;
  }

  function getQueryString(config) {
    let params = '';
    let i = 0;

    for (let param in config.params) {
      if (i != 0) {
        params += `&${param}=${config.params[param]}`;
      } else {
        params = `?${param}=${config.params[param]}`;
      }

      i++;
    }

    return params;
  }

  function getBuiltURL(config) {
    let url = config.url;

    if (getQueryString(config) != '') {
      url =
        url.charAt(url.length - 1) == '/' ? url.substr(0, url.length - 1) : url;
      url += getQueryString(config);
    }

    return url.trim();
  }

  function doReport({ config, reportType, statusCode }) {
    const requestEnd = config.__extra?.requestEnd || 0;
    const requestStart = config.__extra?.requestStart || 0;
    reportType = reportType || 'unknown';

    const url = getBuiltURL(config);
    reporter({
      time: requestEnd - requestStart,
      url,
      method: config.method,
      type: reportType,
      statusCode
    });
  }

  return {
    interceptRequest,
    interceptSuccessResponse,
    interceptErrorResponse
  };
}

function compose(...fns) {
  return async function handler() {
    let op = undefined;
    for (let i = 0; i < fns.length; i++) {
      op = await fns[i].apply(null, arguments);
    }
    return op;
  };
}
